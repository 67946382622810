.Navbar-shadow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 75px;
    background-color: white;
    -webkit-box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.5);
    z-index: 2;
}

.Navbar-no-shadow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 105px;
    background-color: white;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 2;
}

.NameLeft {
    font-family: 'Avenir';
    color: rgb(0, 94, 117);
    font-weight: 800;
    font-size: 25px;
    padding: 6px 12px;
    align-content: center;
    background: -webkit-radial-gradient(left, rgb(0, 94, 117), #04f1f1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Navbar-Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 100px;
}

.Navbar-Right label, #hamburger {
    position: absolute;
    visibility: hidden;
}

.Navbar-Right ul {
    list-style-type: none;
}

.Navbar-Right ul li {
    display: inline-block;
}

.Navbar-Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 200px;
}

@font-face {
    font-family: "Avenir";
    src: url("../Resources/Fonts/Avenir Roman.otf") format("opentype");
}

@media (max-width: 812px) {
    .Navbar-no-shadow {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 105px;
        background-color: white;
        box-shadow: none;
        z-index: 2;
    }
    
    .Navbar-Right {
        display: flex;
        font-size: 15px;
        align-items: center;
        height: 100%;
        margin-right: 0;
    }

    .Navbar-Right label {
        visibility: visible;
        position: absolute;
        display: inline-block;
        font-size: 30px;
        right: 20px;
        top: 30px;
    }
    
    .Navbar-Right ul {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        padding: 100px 0 0 0; 
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background: white;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 28px;
        transition: all 0.3s;
    }

    .Navbar-Right input:checked ~ ul {
        opacity: 1;
        visibility: visible;
        top: 77px;
    }
    
    .Navbar-Right ul li {
        display: inline-block;
        padding: 20px ;
    }
    
    .Navbar-Left {
        margin: 0;
    }
}