.Work {
    background-color: white;
    width: 100%;
}

#First-section {
    padding-top: 215px;
}

.Work-Experience-Section {
    color: black;
    text-align: left;
    padding-top: 80px;
    padding-left: 350px;
}

.Work-Experience-Header {
    /* color: #FF0000; */
    color: #16acac;
    font-size: 25px;
    text-transform: uppercase;
    font: "Avenir";
    font-weight: 600;
}

.Work-Experience-Subheader {
    margin-top: 30px;
    font-weight: 600;
    display: flex;
    font-size: 19px;
}

.Work-Experience-Body {
    margin-top: 20px;
    line-height: 30px;
    width: 650px;
    font-size: 15.5px;
}

.Resume-button {
    font-family: 'Avenir';
    border-style: solid;
    border-width: 1px;
    border-color: #16acac;
    padding: 8px 26px;
    cursor: pointer;
    width: fit-content;
    position: relative;
    right: 270px;
    top: 35px;
    color: #16acac;
    text-decoration: none;
}

.Resume-button:hover {
    background-color: #16acac;
    color: white;
    transition: all 0.5s ease;
}

/* #Instagram{
    background-image: repeating-radial-gradient(circle farthest-corner at 0% 100%, #d77c3d, #c63f78 52%, #aa3f9e 80%, #5156bd);
    color: white;
} */

.Date {
    margin-top: 10px;
    font-style: italic;
    color: rgb(0, 94, 117);
}

.logo {
    height: 25px;
    width: 25px;
    margin-right: 12.5px;
    position: relative;
    top: -1px;
}

.Skills {
    margin-top: 30px;
    margin-bottom: 200px;
    font-size: 14px;
}

.Skills-Body {
    margin-bottom: 8px;
}

@font-face {
    font-family: "Avenir";
    src: url("../../Resources/Fonts/Avenir Roman.otf") format("opentype");
}

@media (max-width: 812px) {
    .Work {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #First-section {
        padding-top: 200px;
    }
    
    .Work-Experience-Section {
        align-self: center;
        padding: 40px 0 0;
        margin-left: 0px;
        width: 80%;
    }
    
    .Work-Experience-Header {
        /* color: #FF0000; */
        color: #16acac;
        font-size: 20px;
        text-transform: uppercase;
        font: "Avenir";
        font-weight: 600;
    }
    
    .Work-Experience-Subheader {
        margin-top: 30px;
        font-weight: 600;
        display: flex;
        font-size: 14px;
    }
    
    .Work-Experience-Body {
        margin-top: 20px;
        line-height: 30px;
        width: 100%;
        font-size: 14px;
    }
    
    .Resume-button {
        position: relative;
        right: 0px;
        top: -75px;
        color: #16acac;
        font-size: 12px;
    }
    
    .Date {
        font-size: 14px;
    }
    
    .logo {
        height: 18px;
        width: 18px;
        position: relative;
    }
}