@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.HomePage {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;
}

.face-header {
    display: flex;
    align-items: center;
    margin: 100px 0px 105px 0px;
    width: 1340px;
}

.IntroBlurb {
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    line-height: 26px;
}

.Face {
    height: 100px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: mediumvioletred;
}

.Work-Section-Row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Work-Section {
    padding: 60px 80px;
    display: flex;
    margin: 10px;
    border-radius: 10px;
    width: 500px;
    height: 300px;
    transition:all 0.3s ease;
    justify-content: center;
    align-items: center;
    position: relative;
}

.Work-Section:hover {
    transform: translateY(-3px);
    cursor: pointer;
}

.Work-Section:hover .Work-Text{
    color: rgba(255,255,255,1);
}

.Work-Section:hover .Instalogo{
    filter: grayscale(0.6) invert(0.6) blur(12px);
}

.Work-Section:hover .fulcrumLogo {
    filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%) blur(8px);
}

.Work-Section:hover .celebRecogLogo{
    filter: blur(8px);
}

.Work-Section-Purdue {
    color: rgba(0,0,0,0);
}

.Work-Section-Purdue:hover .Work-Text{
    color: rgba(0,0,0,1);
}

.Work-Section:hover .purdueLogo{
    filter: blur(8px);
}

.Work-Text {
    font-family: 'Avenir';
    position: absolute;
    width: 100%;
    height: 280px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    padding-top: 150px;
    color: rgba(255,255,255,0);
    z-index: 1;
}

.Work-Title {
    font-weight: 600;
    font-size: 26px;
}

.Work-Body {
    font-size: 22px;
    margin-top: 20px;
    line-height: 22px;
}

.Work-Date {
    font-size: 18px;
    color: #16acac;
    margin-top: 20px;
}

#Insta {
    background-image: repeating-radial-gradient(circle farthest-corner at 10% 100%, #ecc30d, #c63f78 52%, #aa3f9e 80%, #5156bd);
}

.Instalogo {
    width: 100px;
    height: 100px;
    filter: grayscale(1) invert(1);
    padding: 20px 30px;
}

#Fulcrum {
    background-color: rgb(0, 10, 10);
}

.fulcrumLogo {
    width: 200px;
    filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%);
}

#Purdue {
    background-color:rgb(255, 209, 1);
    color: black;
}

.purdueLogo {
    width: 200px;
    border-radius: 20px;
}

#CelebrityRecognition {
    background-color:rgb(238, 68, 68);
}

.celebRecogLogo {
    width: 150px;
}

@font-face {
    font-family: "Avenir";
    src: url("../../Resources/Fonts/Avenir Roman.otf") format("opentype");
}

@media (max-width: 812px) {

    .HomePage {
        width: 100%;
        align-items: center;
    }

    .Work-Section-Row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .face-header {
        display: flex;
        margin: 100px 0px 75px 0px;
        width: 100%;
        justify-content: center;
    }
    
    .IntroBlurb {
        font-size: 15px;
    }
    
    .Work-Section {
        padding: 0;
        display: flex;
        border-radius: 10px;
        width: 90%;
        height: 300px;
        transition:all 0.3s ease;
        position: relative;
    }
   
    .Work-Text {
        font-family: 'Avenir';
        position: absolute;
        width: 100%;
        /* height: 200px; */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        padding-top: 100px;
        color: rgba(255,255,255,0);
        z-index: 1;
    }

    .Instalogo {
        width: 100px;
        height: 100px;
        padding: 20px 30px;
    }
    
    .fulcrumLogo {
        width: 200px;
    }
    
    .purdueLogo {
        width: 150px;
        border-radius: 20px;
    }

    .celebRecogLogo {
        width: 100px;
    }
}