

.SlideIn-enter {
  /* transform: translateY(-200vh); */
  opacity: 0;
}
.SlideIn-enter.SlideIn-enter-active {
  /* transform: translateY(0); */
  transition: all 1.5s;
  opacity: 1;
}
.SlideIn-exit {
  transform: translateY(0);
  opacity: 1;
}
.SlideIn-exit.SlideIn-exit-active {
  /* position: absolute; */
  /* transform: translateY(-400vh); */
  opacity: 0;
  transition: all 1.5s;
}
