@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo:100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo:100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.SlideIn-enter {
  /* transform: translateY(-200vh); */
  opacity: 0;
}
.SlideIn-enter.SlideIn-enter-active {
  /* transform: translateY(0); */
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
  opacity: 1;
}
.SlideIn-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.SlideIn-exit.SlideIn-exit-active {
  /* position: absolute; */
  /* transform: translateY(-400vh); */
  opacity: 0;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}

.Navbar-shadow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 75px;
    background-color: white; 
    box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.5);
    z-index: 2;
}

.Navbar-no-shadow {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 105px;
    background-color: white;
    box-shadow: none;
    z-index: 2;
}

.NameLeft {
    font-family: 'Avenir';
    color: rgb(0, 94, 117);
    font-weight: 800;
    font-size: 25px;
    padding: 6px 12px;
    align-content: center;
    background: -webkit-radial-gradient(left, rgb(0, 94, 117), #04f1f1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Navbar-Right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 100px;
}

.Navbar-Right label, #hamburger {
    position: absolute;
    visibility: hidden;
}

.Navbar-Right ul {
    list-style-type: none;
}

.Navbar-Right ul li {
    display: inline-block;
}

.Navbar-Left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 200px;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/media/Avenir Roman.b3b06989.otf") format("opentype");
}

@media (max-width: 812px) {
    .Navbar-no-shadow {
        display: flex;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 105px;
        background-color: white;
        box-shadow: none;
        z-index: 2;
    }
    
    .Navbar-Right {
        display: flex;
        font-size: 15px;
        align-items: center;
        height: 100%;
        margin-right: 0;
    }

    .Navbar-Right label {
        visibility: visible;
        position: absolute;
        display: inline-block;
        font-size: 30px;
        right: 20px;
        top: 30px;
    }
    
    .Navbar-Right ul {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        padding: 100px 0 0 0; 
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background: white;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 28px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .Navbar-Right input:checked ~ ul {
        opacity: 1;
        visibility: visible;
        top: 77px;
    }
    
    .Navbar-Right ul li {
        display: inline-block;
        padding: 20px ;
    }
    
    .Navbar-Left {
        margin: 0;
    }
}
.Button {
    font-family: 'Avenir';
    color: black;
    padding: 8px 16px;
    margin: 0px 20px;
    cursor: pointer;
}

.Button-Active {
    color: #16acac;
}

.Button:hover {
    color: #16acac;
}

@media (max-width: 812px) {
    .Button {
        padding: 10px 16px;
    }
}
.HomePage {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;
}

.face-header {
    display: flex;
    align-items: center;
    margin: 100px 0px 105px 0px;
    width: 1340px;
}

.IntroBlurb {
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    line-height: 26px;
}

.Face {
    height: 100px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: mediumvioletred;
}

.Work-Section-Row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Work-Section {
    padding: 60px 80px;
    display: flex;
    margin: 10px;
    border-radius: 10px;
    width: 500px;
    height: 300px;
    -webkit-transition:all 0.3s ease;
    transition:all 0.3s ease;
    justify-content: center;
    align-items: center;
    position: relative;
}

.Work-Section:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    cursor: pointer;
}

.Work-Section:hover .Work-Text{
    color: rgba(255,255,255,1);
}

.Work-Section:hover .Instalogo{
    -webkit-filter: grayscale(0.6) invert(0.6) blur(12px);
            filter: grayscale(0.6) invert(0.6) blur(12px);
}

.Work-Section:hover .fulcrumLogo {
    -webkit-filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%) blur(8px);
            filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%) blur(8px);
}

.Work-Section:hover .celebRecogLogo{
    -webkit-filter: blur(8px);
            filter: blur(8px);
}

.Work-Section-Purdue {
    color: rgba(0,0,0,0);
}

.Work-Section-Purdue:hover .Work-Text{
    color: rgba(0,0,0,1);
}

.Work-Section:hover .purdueLogo{
    -webkit-filter: blur(8px);
            filter: blur(8px);
}

.Work-Text {
    font-family: 'Avenir';
    position: absolute;
    width: 100%;
    height: 280px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    padding-top: 150px;
    color: rgba(255,255,255,0);
    z-index: 1;
}

.Work-Title {
    font-weight: 600;
    font-size: 26px;
}

.Work-Body {
    font-size: 22px;
    margin-top: 20px;
    line-height: 22px;
}

.Work-Date {
    font-size: 18px;
    color: #16acac;
    margin-top: 20px;
}

#Insta {
    background-image: -webkit-repeating-radial-gradient(10% 100%, circle farthest-corner, #ecc30d, #c63f78 52%, #aa3f9e 80%, #5156bd);
    background-image: repeating-radial-gradient(circle farthest-corner at 10% 100%, #ecc30d, #c63f78 52%, #aa3f9e 80%, #5156bd);
}

.Instalogo {
    width: 100px;
    height: 100px;
    -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
    padding: 20px 30px;
}

#Fulcrum {
    background-color: rgb(0, 10, 10);
}

.fulcrumLogo {
    width: 200px;
    -webkit-filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%);
            filter: invert(0.98) sepia(0.53) saturate(45.95) hue-rotate(167deg) brightness(100%) contrast(102%);
}

#Purdue {
    background-color:rgb(255, 209, 1);
    color: black;
}

.purdueLogo {
    width: 200px;
    border-radius: 20px;
}

#CelebrityRecognition {
    background-color:rgb(238, 68, 68);
}

.celebRecogLogo {
    width: 150px;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/media/Avenir Roman.b3b06989.otf") format("opentype");
}

@media (max-width: 812px) {

    .HomePage {
        width: 100%;
        align-items: center;
    }

    .Work-Section-Row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .face-header {
        display: flex;
        margin: 100px 0px 75px 0px;
        width: 100%;
        justify-content: center;
    }
    
    .IntroBlurb {
        font-size: 15px;
    }
    
    .Work-Section {
        padding: 0;
        display: flex;
        border-radius: 10px;
        width: 90%;
        height: 300px;
        -webkit-transition:all 0.3s ease;
        transition:all 0.3s ease;
        position: relative;
    }
   
    .Work-Text {
        font-family: 'Avenir';
        position: absolute;
        width: 100%;
        /* height: 200px; */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        padding-top: 100px;
        color: rgba(255,255,255,0);
        z-index: 1;
    }

    .Instalogo {
        width: 100px;
        height: 100px;
        padding: 20px 30px;
    }
    
    .fulcrumLogo {
        width: 200px;
    }
    
    .purdueLogo {
        width: 150px;
        border-radius: 20px;
    }

    .celebRecogLogo {
        width: 100px;
    }
}
.Work {
    background-color: white;
    width: 100%;
}

#First-section {
    padding-top: 215px;
}

.Work-Experience-Section {
    color: black;
    text-align: left;
    padding-top: 80px;
    padding-left: 350px;
}

.Work-Experience-Header {
    /* color: #FF0000; */
    color: #16acac;
    font-size: 25px;
    text-transform: uppercase;
    font: "Avenir";
    font-weight: 600;
}

.Work-Experience-Subheader {
    margin-top: 30px;
    font-weight: 600;
    display: flex;
    font-size: 19px;
}

.Work-Experience-Body {
    margin-top: 20px;
    line-height: 30px;
    width: 650px;
    font-size: 15.5px;
}

.Resume-button {
    font-family: 'Avenir';
    border-style: solid;
    border-width: 1px;
    border-color: #16acac;
    padding: 8px 26px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    right: 270px;
    top: 35px;
    color: #16acac;
    text-decoration: none;
}

.Resume-button:hover {
    background-color: #16acac;
    color: white;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* #Instagram{
    background-image: repeating-radial-gradient(circle farthest-corner at 0% 100%, #d77c3d, #c63f78 52%, #aa3f9e 80%, #5156bd);
    color: white;
} */

.Date {
    margin-top: 10px;
    font-style: italic;
    color: rgb(0, 94, 117);
}

.logo {
    height: 25px;
    width: 25px;
    margin-right: 12.5px;
    position: relative;
    top: -1px;
}

.Skills {
    margin-top: 30px;
    margin-bottom: 200px;
    font-size: 14px;
}

.Skills-Body {
    margin-bottom: 8px;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/media/Avenir Roman.b3b06989.otf") format("opentype");
}

@media (max-width: 812px) {
    .Work {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #First-section {
        padding-top: 200px;
    }
    
    .Work-Experience-Section {
        align-self: center;
        padding: 40px 0 0;
        margin-left: 0px;
        width: 80%;
    }
    
    .Work-Experience-Header {
        /* color: #FF0000; */
        color: #16acac;
        font-size: 20px;
        text-transform: uppercase;
        font: "Avenir";
        font-weight: 600;
    }
    
    .Work-Experience-Subheader {
        margin-top: 30px;
        font-weight: 600;
        display: flex;
        font-size: 14px;
    }
    
    .Work-Experience-Body {
        margin-top: 20px;
        line-height: 30px;
        width: 100%;
        font-size: 14px;
    }
    
    .Resume-button {
        position: relative;
        right: 0px;
        top: -75px;
        color: #16acac;
        font-size: 12px;
    }
    
    .Date {
        font-size: 14px;
    }
    
    .logo {
        height: 18px;
        width: 18px;
        position: relative;
    }
}
.Footer-Container {
    display: flex;
    /* position: absolute; */
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    justify-content: center;
}

.Footer-Content {
    align-self: center;
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: rgb(183, 197, 200);
}

.NameLogo {
    display: flex;
    font-family: 'Avenir';
    font-weight: 500;
    font-size: 20px;
    align-self: center;
    margin-left: 70px;
}

.Social-Media {
    display: flex;
    font-family: 'Avenir';
    align-self: center;
    font-size: 20px;
    color: rgb(150, 158, 160);
}

.Social-Media-Link {
    margin-right: 30px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.Social-Media-Link:hover {
    color: rgb(0, 94, 117);
}

a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 812px) {
    .Footer-Container {
        display: flex;
        /* position: absolute; */
        left: 0;
        bottom: 0;
        width: 100%;
        height: 200px;
        justify-content: center;
    }
    
    .Footer-Content {
        align-self: center;
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        border-width: 1px 0px 0px 0px;
        border-style: solid;
        border-color: rgb(183, 197, 200);
    }
    
    .NameLogo {
        display: flex;
        font-family: 'Avenir';
        font-weight: 500;
        font-size: 14px;
        align-self: center;
        margin-left: 20px;
    }
    
    .Social-Media {
        display: flex;
        flex-direction: column;
        font-family: 'Avenir';
        align-self: center;
        font-size: 14px;
        color: rgb(150, 158, 160);
    }
    
    .Social-Media-Link {
        margin-right: 30px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    
    .Social-Media-Link:hover {
        color: rgb(0, 94, 117);
    }
    
    a {
        text-decoration: none;
        color: inherit;
    }
}

@font-face {
    font-family: "Avenir";
    src: url("/static/media/Avenir Roman.b3b06989.otf") format("opentype");
}
.About-page {
    /* height: 100%; */
    width: 100%;
    display: flex;
    padding-top: 200px;
    padding-bottom: 100px;
}

.LeftPicture {
    max-width: 600px;
    max-height: 752px;
    width: 50%;
    height: 62.65%;
    margin-left: 5%;
}

.Intro {
    color: #16acac;
    display: flex;
    flex-direction: column;
    margin-left: 150px;
}


.Intro-1 {
    font-family: 'AvenirNext-Ultralight';
    font-size: 45px;
}

.Intro-2 {
    margin-top: 15px;
    font-family: 'Avenir', sans-serif;
    font-weight: 900;
    font-size: 65px;
}

.Intro-3 {
    color: black;
    margin-top: 75px;
    font-family: 'AvenirNext-Ultralight', sans-serif;
    font-size: 20px;
    width: 75%;
}

.Pronunciation {
    font-family: 'Avenir', sans-serif;
    color: rgb(0, 94, 117);
    font-size: 16px;
    font-weight: 800;
}

@font-face {
    font-family: "Avenir";
    src: url("/static/media/Avenir Roman.b3b06989.otf") format("opentype");
}

@media (max-width: 812px) {
    .About-page {
        padding-top: 100px;
        flex-direction: column;
        width: 100%;
    }
    
    .LeftPicture {
        width: 100%;
        height: auto;
        margin: 0;
    }
    
    .Intro {
        color: #16acac;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0;
        align-self: center;
        width: 80%;
    }
    
    
    .Intro-1 {
        font-family: 'AvenirNext-Ultralight';
        font-size: 35px;
    }
    
    .Intro-2 {
        margin-top: 15px;
        font-family: 'Avenir', sans-serif;
        font-weight: 900;
        font-size: 50px;
    }
    
    .Intro-3 {
        color: black;
        margin-top: 75px;
        font-family: 'AvenirNext-Ultralight', sans-serif;
        font-size: 18px;
        width: 100%;
    }
    
    .Pronunciation {
        font-family: 'Avenir', sans-serif;
        color: rgb(0, 94, 117);
        font-size: 12px;
        font-weight: 800;
    }
}
.Insta-page {
    padding: 100px 0 100px 0;
    display: flex;
    flex-direction: column;
}

.InstaHero {
    display: flex;
    justify-content: center;
    align-self: center;
    /* background: rgb(186, 168, 139); */
    background: rgb(219, 208, 196);
    width: 90%;
}

.InstaHeroImage {
    width: 700px;
    height: 726.34px;
}

.DetailContainer {
    display: flex;
    flex-direction: column;
    margin: 100px 0;
    padding-left: 200px;
}

.Title {
    font-family: 'Heebo', sans-serif;
    font-size: 56px;
}

.Details {
    margin-top: 40px;
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 1000px;
}

.InstaMPK {
    margin-top: 100px;
}

.InstaMPKImage {
    width: 800px;
}

@media (max-width: 812px) {
    .Insta-page {
        width: 100%;
    }
    
    .InstaHero {
        width: 100%;
    }
    
    .InstaHeroImage {
        width: 100%;
        height: 86.47%;
    }
    
    .DetailContainer {
        margin: 0;
        padding: 0 20px;
    }
    
    .Title {
        font-size: 40px;
    }
    
    .Details {
       width: 100%;
       font-size: 16px;
    }
    
    .InstaMPKImage {
        width: 100%;
    }
}
.Fulcrum-page {
    padding: 200px 0 200px 0;
    display: flex;
    flex-direction: column;
}

.FulcrumHero {
    position: absolute;
    left: 5%;
    /* background: rgb(186, 168, 139); */
    /* background: rgb(0, 193, 255); */
    width: 90%;
}

.FulcrumHeroImage {
    max-width: 700px;
    width: 45%;
    /* height: 726.34px; */
}

.Hancock {
    /* position: absolute;
    top: 800px;
    left: 300px; */
    margin: 50px 0 0 0;
    padding-left: 120px;
}

.HancockImage {
    width: 500px;
}

.Lolla {
    position: absolute;
    left: 3%;
    top: 800px;
}

.LollaImage {
    width: 500px;
}

.LastDay {
    position: absolute;
    top: 100%;
    left: 35%;
    /* left: 500px; */
}

.LastDayImage {
    width: 400px;
}

.FulcrumDetailContainer {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    padding-left: 55%;
}

.Title {
    font-family: 'Heebo', sans-serif;
    font-size: 56px;
}

.Details {
    margin-top: 40px;
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 80%;
}

@media (max-width: 812px) {
    .Fulcrum-page {
        width: 100%;
        align-content: center;
    }

    .FulcrumHero {
        position: static;
        width: 100%;
    }
    
    .FulcrumHeroImage {
        max-width: 100%;
        width: 100%;
        /* height: 726.34px; */
    }
    
    .Hancock {
        /* position: absolute;
        top: 800px;
        left: 300px; */
        margin: 0;
        padding-left: 0px;
    }
    
    .HancockImage {
        width: 100%;
    }
    
    .Lolla {
        position: static;
    }
    
    .LollaImage {
        width: 100%;
        margin-top: 40px;
    }
    
    .LastDay {
        position: static;
    }
    
    .LastDayImage {
        width: 100%;
    }    

    .FulcrumDetailContainer {
        margin: 0;
        padding: 0 20px;
    }
    
    .Title {
        font-size: 40px;
    }
    
    .Details {
        font-size: 16px;
        width: 100%;
    }
}
