.Button {
    font-family: 'Avenir';
    color: black;
    padding: 8px 16px;
    margin: 0px 20px;
    cursor: pointer;
}

.Button-Active {
    color: #16acac;
}

.Button:hover {
    color: #16acac;
}

@media (max-width: 812px) {
    .Button {
        padding: 10px 16px;
    }
}