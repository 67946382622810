.About-page {
    /* height: 100%; */
    width: 100%;
    display: flex;
    padding-top: 200px;
    padding-bottom: 100px;
}

.LeftPicture {
    max-width: 600px;
    max-height: 752px;
    width: 50%;
    height: 62.65%;
    margin-left: 5%;
}

.Intro {
    color: #16acac;
    display: flex;
    flex-direction: column;
    margin-left: 150px;
}


.Intro-1 {
    font-family: 'AvenirNext-Ultralight';
    font-size: 45px;
}

.Intro-2 {
    margin-top: 15px;
    font-family: 'Avenir', sans-serif;
    font-weight: 900;
    font-size: 65px;
}

.Intro-3 {
    color: black;
    margin-top: 75px;
    font-family: 'AvenirNext-Ultralight', sans-serif;
    font-size: 20px;
    width: 75%;
}

.Pronunciation {
    font-family: 'Avenir', sans-serif;
    color: rgb(0, 94, 117);
    font-size: 16px;
    font-weight: 800;
}

@font-face {
    font-family: "Avenir";
    src: url("../../Resources/Fonts/Avenir Roman.otf") format("opentype");
}

@media (max-width: 812px) {
    .About-page {
        padding-top: 100px;
        flex-direction: column;
        width: 100%;
    }
    
    .LeftPicture {
        width: 100%;
        height: auto;
        margin: 0;
    }
    
    .Intro {
        color: #16acac;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0;
        align-self: center;
        width: 80%;
    }
    
    
    .Intro-1 {
        font-family: 'AvenirNext-Ultralight';
        font-size: 35px;
    }
    
    .Intro-2 {
        margin-top: 15px;
        font-family: 'Avenir', sans-serif;
        font-weight: 900;
        font-size: 50px;
    }
    
    .Intro-3 {
        color: black;
        margin-top: 75px;
        font-family: 'AvenirNext-Ultralight', sans-serif;
        font-size: 18px;
        width: 100%;
    }
    
    .Pronunciation {
        font-family: 'Avenir', sans-serif;
        color: rgb(0, 94, 117);
        font-size: 12px;
        font-weight: 800;
    }
}