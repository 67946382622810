.Fulcrum-page {
    padding: 200px 0 200px 0;
    display: flex;
    flex-direction: column;
}

.FulcrumHero {
    position: absolute;
    left: 5%;
    /* background: rgb(186, 168, 139); */
    /* background: rgb(0, 193, 255); */
    width: 90%;
}

.FulcrumHeroImage {
    max-width: 700px;
    width: 45%;
    /* height: 726.34px; */
}

.Hancock {
    /* position: absolute;
    top: 800px;
    left: 300px; */
    margin: 50px 0 0 0;
    padding-left: 120px;
}

.HancockImage {
    width: 500px;
}

.Lolla {
    position: absolute;
    left: 3%;
    top: 800px;
}

.LollaImage {
    width: 500px;
}

.LastDay {
    position: absolute;
    top: 100%;
    left: 35%;
    /* left: 500px; */
}

.LastDayImage {
    width: 400px;
}

.FulcrumDetailContainer {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    padding-left: 55%;
}

.Title {
    font-family: 'Heebo', sans-serif;
    font-size: 56px;
}

.Details {
    margin-top: 40px;
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    line-height: 26px;
    width: 80%;
}

@import url('https://fonts.googleapis.com/css?family=Heebo:100&display=swap');

@media (max-width: 812px) {
    .Fulcrum-page {
        width: 100%;
        align-content: center;
    }

    .FulcrumHero {
        position: static;
        width: 100%;
    }
    
    .FulcrumHeroImage {
        max-width: 100%;
        width: 100%;
        /* height: 726.34px; */
    }
    
    .Hancock {
        /* position: absolute;
        top: 800px;
        left: 300px; */
        margin: 0;
        padding-left: 0px;
    }
    
    .HancockImage {
        width: 100%;
    }
    
    .Lolla {
        position: static;
    }
    
    .LollaImage {
        width: 100%;
        margin-top: 40px;
    }
    
    .LastDay {
        position: static;
    }
    
    .LastDayImage {
        width: 100%;
    }    

    .FulcrumDetailContainer {
        margin: 0;
        padding: 0 20px;
    }
    
    .Title {
        font-size: 40px;
    }
    
    .Details {
        font-size: 16px;
        width: 100%;
    }
}